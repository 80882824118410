<template>
  <div class="home">
    <div class="home-top">
      <div class="top-search flex-center">
        <div class="search-img flex-center">
          <img src="@/assets/home/search.png" alt="" />
        </div>
        <input type="text" placeholder="搜索" />
      </div>
    </div>
    <div class="home-bottom">
      <div class="bottom-nav">
        <div
          v-for="item in selectDisplayList"
          :key="item.id"
          @click="navHandle(item, item.params)"
        >
          <img :src="item.imgurl" alt="" />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdata } from '@/api/index.js'
export default {
  name: 'LibraryScreenHome',

  data() {
    return {
      selectDisplayList: [
        {
          id: 1,
          title: '竖屏1',
          imgurl: require('@/assets/home/verticalScreenCarouselOne.png'),
          async click(thit) {
            let { data } = await getdata('')
            let datalen = data.length
            let params = data[datalen - 1].sceneName
            thit.$router.push(`/verticalScreenCarouselOne?pathparams=${params}`)
          },
        },
        {
          id: 2,
          title: '竖屏2',
          imgurl: require('@/assets/home/verticalScreenCarouselTwo.png'),
          async click(thit) {
            let { data } = await getdata('')
            let datalen = data.length
            let params = data[datalen - 2].sceneName
            thit.$router.push(`/verticalScreenCarouselTwo?pathparams=${params}`)
          },
        },
        {
          id: 3,
          title: '圆形投影',
          imgurl: require('@/assets/home/circular-carousel.png'),
         async click(thit) {
            let { data } = await getdata('')
            let datalen = data.length
            let params = data[datalen - 3].sceneName
            thit.$router.push(`/circularCarousel?pathparams=${params}`)
          },
        },
        {
          id: 4,
          title: '长方形投影',
          imgurl: require('@/assets/home/rectangular-carousel.png'),
          async click(thit) {
            let { data } = await getdata('')
            let datalen = data.length
            let params = data[datalen - 4].sceneName
            thit.$router.push(`/rectangularCarousel?pathparams=${params}`)
          },
        },
        {
          id: 5,
          title: '正方形投影',
          imgurl: require('@/assets/home/square-carousel.png'),
         async click(thit) {
            let { data } = await getdata('')
            let datalen = data.length
            let params = data[datalen - 5].sceneName
            thit.$router.push(`/squareCarousel?pathparams=${params}`)
          },
        },
        {
          id: 6,
          title: '校园风采',
          imgurl: require('@/assets/home/campus-style-carousel.png'),
          async click(thit) {
            let { data } = await getdata('')
            let datalen = data.length
            let params = data[datalen - 6].sceneName
            thit.$router.push(`/campusStyleCarousel?pathparams=${params}`)
          },
        },
      ],
    }
  },

  mounted() {
    console.log('当前环境',process.env.NODE_ENV);
    console.log('当前使用的接口',process.env.VUE_APP_URL);
  },

  methods: {
    /** 宫格点击 */
    navHandle(item) {
      if (item.click) {
        item.click(this)
      } else if (item.path) {
        this.$router.push(item.path)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@media (max-width: 750px) {
  .home {
    background-size: cover !important;
  }
  .home .home-top .top-search {
    width: 80% !important;
  }
}
.home {
  width: 100%;
  height: 100%;
  background-image: url('@/assets/home/home-bg.png');
  background-size: 100% 100%;
  .home-top {
    width: 100%;
    height: 40%;
    position: relative;
    .top-search {
      top: 75%;
      width: 480px;
      height: 35px;
      background: #ffffff;
      border-radius: 22px;
      position: relative;
      .search-img {
        width: 18px;
        height: 18px;
        left: 100%;
        img {
          height: 100%;
          width: 100%;
        }
      }
      input {
        width: 100%;
        height: 100%;
        border-radius: 22px;
        border: none;
        outline: none;
        padding-left: 30px;
        font-size: 14px;
      }
    }
  }
  .home-bottom {
    width: 100%;
    height: 60%;
    .bottom-nav {
      width: 100%;
      // height: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      div {
        width: 80px;
        height: 110px;
        margin: 0 3% 6% 3%;
        img {
          width: 100%;
          height: 80px;
        }
        p {
          padding-top: 10px;
          width: 100%;
          height: 20%;
          color: #ffffff;
          text-align: center;
          // font-size: 0.18rem;
        }
      }
    }
  }
}
</style>

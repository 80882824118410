import axios from 'axios'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_URL + process.env.VUE_APP_BASE_API,
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  if (code === 401) {
    console.log(401);
  } else if (code === 500) {
    console.log(500);
    return Promise.reject(new Error());
  } else if (code !== 200) {
    console.log('!200');
    return Promise.reject('error');
  } else {
    return res.data
  }
},
  error => {
    alert('服务器繁忙')
    console.log('请求结束后错误', error)
    return Promise.reject(error)
  }
)
export default service

;(() => {
  const doc = document.documentElement
  let dpr = window.devicePixelRatio || 1
  if (dpr >= 3) {
    dpr = 3
  } else if (dpr >= 2) {
    dpr = 2
  } else {
    dpr = 1
  }
  doc.setAttribute('data-dpr', dpr)

  function fit() {
    let w = doc.getBoundingClientRect().width
    /* 限制最大尺寸 */
    if (w / dpr > 1080) {
      w = 1080 * dpr
    }
    // 当前设备的屏幕宽度  / 设计稿的宽度
    doc.style.cssText = `font-size:${(w * 100) / 1080}px;`
  }
  fit()
  /** 兼容移动端 */
  const eventName = window.onorientationchange ? 'orientationchange' : 'resize'
  window.addEventListener(eventName, fit)
})()

import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)
import home from '@/views/home.vue'
const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/rectangularCarousel",
    name: "rectangularCarousel",
    component: () => import("@/views/rectangularCarousel"),
  },
  {
    path: "/campusStyleCarousel",
    name: "campusStyleCarousel",
    component: () => import("@/views/campusStyleCarousel"),
  },
  {
    path: "/squareCarousel",
    name: "squareCarousel",
    component: () => import("@/views/squareCarousel"),
  },
  {
    path: "/circularCarousel",
    name: "circularCarousel",
    component: () => import("@/views/circularCarousel"),
  },
  {
    path: "/verticalScreenCarouselOne",
    name: "verticalScreenCarouselOne",
    component: () => import("@/views/verticalScreenCarouselOne"),
  },
  {
    path: "/verticalScreenCarouselTwo",
    name: "verticalScreenCarouselTwo",
    component: () => import("@/views/verticalScreenCarouselTwo"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
